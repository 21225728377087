import '../styles/pages/home.scss';
import * as React from "react"
import {HeadFC, Link, navigate, PageProps} from "gatsby"
import {useEffect, useMemo, useState, useRef} from "react";
import Slider from "react-slick";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {
    corporativeMan,
    corporativePhone,
    corporativeGoogleplay,
    corporativeApple,
    corporativeShape1,
    corporativeShape2,
    corporativeShape1Mob,
    corporativeShape2Mob,
    corporativeStar,
    corporativeUser1,
    corporativeUser2,
    corporativeUser3,
    masterCardV2,
    VisaV2,
    corporativeLogo,
    BigLogoRight,
    AvatarImage,
    corporativeSmart1,
    corporativeSmart2,
    corporativeSmart3,
    corporativeSmart4,
    corporativeSmart5,
    corporativeLearn,
    corporativeScreen1,
    corporativeScreen2,
    corporativeScreen3,
    corporativeScreen4,
    corporativeScreen5,
    corporativeBook1,
    corporativeBook2,
    corporativeBook3,
    corporativeBook4,
    corporativeBook5,
    corporativeBook6,
    corporativeBook7,
    corporativeUser,
    corporativeCourse1,
    corporativeCourse2,
    corporativeCourse3,
    corporativeCourse4,
    corporativeCourse5,
    corporativeCourse6,
    corporativeCourse7,
    corporativeBio1,
    corporativeBio2,
    corporativeBio3,
    corporativeBio4,
    corporativeBio5,
    corporativeBio6,
    corporativeBio7new,
    corporativeScreenMob1,
    corporativeScreenMob2,
    corporativeScreenMob3,
    corporativeScreenMob4,
    corporativeScreenMob5,
} from "../images";
import {toast, ToastContainer} from "react-toastify";
import CorporativeBookItem from '../components/CorporativeBookItem';
import HeaderCorporative from '../components/HeaderCorporative';
import MetaPixel from '../utils/tiktokPixel';

// type DataProps = {
//     path: string
// }

const settingsFeed = {
    dots: false,
    infinite: true,
    // speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};
const settingsBook = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
    ]
};
const settingsScreens = {
    dots: false,
    infinite: true,
    // speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};



const MainCorporativePage = ({}: PageProps) => {

    const bios = [
        {
            id: 1,
            image: corporativeBio1,
            title: 'Elon Musk',
            subtitle: 'Tesla, SpaceX, and the Quest for a Fantastic Future'
        },
        {
            id: 2,
            image: corporativeBio2,
            title: 'Jack Ma',
            subtitle: 'How I Created Alibaba'
        },
        {
            id: 3,
            image: corporativeBio3,
            title: 'Steve Jobs',
            subtitle: "The story of Apple's founder"
        },
        {
            id: 4,
            image: corporativeBio4,
            title: 'Richard Branson',
            subtitle: "Autobiography of the Virgin Group's founder"
        },
        {
            id: 5,
            image: corporativeBio5,
            title: 'Shoe Dog',
            subtitle: "The story of Nike's creation"
        },
        {
            id: 6,
            image: corporativeBio6,
            title: 'Henry Ford',
            subtitle: 'Autobiography of the Ford founder'
        },
        {
            id: 7,
            image: corporativeBio7new,
            title: 'Jeff Bezos',
            subtitle: 'A detailed look at the creation of Amazon'
        },
    ];

    const books = [
        {
            id: 1,
            image: corporativeBook1,
            title: "Rich Dad Poor Dad",
            subtitle: "Robert Kiyosaki"
        },
        {
            id: 2,
            image: corporativeBook2,
            title: "Zero to One",
            subtitle: "Peter Thiel"
        },
        {
            id: 3,
            image: corporativeBook3,
            title: "The Great Gatsby",
            subtitle: "F. Scott Fitzgerald"
        },
        {
            id: 4,
            image: corporativeBook4,
            title: "Fahrenheit 451",
            subtitle: "Ray Bradbury"
        },
        {
            id: 5,
            image: corporativeBook5,
            title: "The Catcher in the Rye",
            subtitle: "J. D. Salinger"
        },
        {
            id: 6,
            image: corporativeBook6,
            title: "The Monk who Sold Ferrari",
            subtitle: "Robin Sharma"
        },
        {
            id: 7,
            image: corporativeBook7,
            title: "Unlimited Memory",
            subtitle: "Kevin Horsley"
        },
    ];

    const courses = [
        {
            id: 1,
            image: corporativeCourse1,
            title: "Step 2: Proven goal-setting and...",
            subtitle: "FeelGrow Team"
        },
        {
            id: 2,
            image: corporativeCourse2,
            title: "Improving Presentation Skills",
            subtitle: "FeelGrow Team"
        },
        {
            id: 3,
            image: corporativeCourse3,
            title: "Step 1: How to get the most out of FeelGrow",
            subtitle: "FeelGrow Team"
        },
        {
            id: 4,
            image: corporativeCourse4,
            title: "A powerful to-do task list by FeelGrow",
            subtitle: "FeelGrow Team"
        },
        {
            id: 5,
            image: corporativeCourse5,
            title: "Psychotypes of the modern person",
            subtitle: "FeelGrow Team"
        },
        {
            id: 6,
            image: corporativeCourse6,
            title: "Analysis of the objectives set",
            subtitle: "FeelGrow Team"
        },
        {
            id: 7,
            image: corporativeCourse7,
            title: "Use artificial intelligence to your advantage",
            subtitle: "FeelGrow Team"
        },
    ];

    const [activeTab, setActiveTab] = useState('bio');
    const [isWideScreen, setIsWideScreen] = useState<boolean>(false);
    const [centerPadding, setCenterPadding] = useState('0px');


    useEffect(() => {
        if (typeof window !== 'undefined'){
            setIsWideScreen(window.innerWidth > 767);

            const handleResize = () => {
                setIsWideScreen(window.innerWidth > 767);
            };

            setCenterPadding(`${window.innerWidth * 0.24}px`)

            window.addEventListener('resize', handleResize);

            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
        
    }, []);

    const renderCourses = () => {
        switch (activeTab) {
            case 'bio':
                return <Slider initialSlide={0} {...settingsBook} key="slider-1" className="corporativeBooksBox">
                    {bios.map((data) => (
                        <CorporativeBookItem
                            key={`bio-${data.id}`}
                            number={`bio-${data.id}`} 
                            image={data.image} 
                            title={data.title}
                            subtitle={data.subtitle} />
                    ))}
                </Slider>;
            case 'book':
                return <Slider initialSlide={0} {...settingsBook} key="slider-2" className="corporativeBooksBox">
                    {books.map((data) => (
                        <CorporativeBookItem
                            key={`book-${data.id}`}
                            number={`book-${data.id}`} 
                            image={data.image} 
                            title={data.title}
                            subtitle={data.subtitle} />
                    ))}
                </Slider>;
            case 'course':
                return <Slider initialSlide={0} {...settingsBook} key="slider-3" className="corporativeBooksBox">
                    {courses.map((data) => (
                        <CorporativeBookItem
                            key={`course-${data.id}`}
                            number={`course-${data.id}`} 
                            image={data.image} 
                            title={data.title}
                            subtitle={data.subtitle} />
                    ))}
                </Slider>;
            default:
              return <div>Error: Invalid value</div>;
        }
    }

    const renderFeed = () => {
        if(isWideScreen){
            return <div className="corporativeFeedBox">
                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser1} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Vishu</span>
                            <p>Painting Professional <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I was surprised by the amazing features of the app that I hadn’t known about before. The app provides quick insight into books, allowing me to read key ideas, quotes, and messages in literally 15 minutes. Hands down, one of the best apps I’ve come across</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 10, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser2} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Jared</span>
                            <p>Digital Marketing Maven <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>Listening to FeelGrow while running. It’s an app that provides thousands of insights from the world’s best books, courses, documentaries, and podcasts. So many titles to choose from.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 19, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser3} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Mike</span>
                            <p>Web Dev Enthusiast <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I always have LOVED learning. But sometimes, it feels overwhelming, especially when you don’t know where to start. There are so many topics, so many books to read, so many lessons out there. I am obsessed and had no idea this is what I needed in my life. I’m saving you so much time.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 30, 2023</span>
                </div>
            </div>
        } else{
            return <Slider initialSlide={0} {...settingsFeed} className="corporativeFeedBox">
                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser1} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Vishu</span>
                            <p>Painting Professional <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I was surprised by the amazing features of the app that I hadn’t known about before. The app provides quick insight into books, allowing me to read key ideas, quotes, and messages in literally 15 minutes. Hands down, one of the best apps I’ve come across</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 10, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser2} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Jared</span>
                            <p>Digital Marketing Maven <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>Listening to FeelGrow while running. It’s an app that provides thousands of insights from the world’s best books, courses, documentaries, and podcasts. So many titles to choose from.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 19, 2023</span>
                </div>

                <div className="corporativeFeedItem">
                    <div className="corporativeFeedUser">
                        <div className="corporativeFeedUserIcon">
                            <img src={corporativeUser3} alt="" />
                        </div>
                        <div className="corporativeFeedUserInfo">
                            <span>Mike</span>
                            <p>Web Dev Enthusiast <br /> Student at FeelGrow</p>
                        </div>
                    </div>
                    <div className="corporativeFeedStars">
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                        <img src={corporativeStar} alt="" />
                    </div>
                    <div className="corporativeFeedDescr">
                        <p>I always have LOVED learning. But sometimes, it feels overwhelming, especially when you don’t know where to start. There are so many topics, so many books to read, so many lessons out there. I am obsessed and had no idea this is what I needed in my life. I’m saving you so much time.</p>
                    </div>
                    <span className="corporativeFeedDate">Jul 30, 2023</span>
                </div>
            </Slider>
        }
    }

    const renderScreens = () => {
        if(isWideScreen){
            return <div className="corporativePathBox">
                <div className="corporativePathItem">
                    <img src={corporativeSmart1} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart2} alt="" />
                </div>
                <div className="corporativePathItem big">
                    <img src={corporativeSmart3} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart4} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart5} alt="" />
                </div>
            </div>
        } else{
            return <Slider initialSlide={0} centerMode {...settingsScreens} centerPadding={centerPadding} className="corporativePathBox">
                <div className="corporativePathItem">
                    <img src={corporativeSmart1} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart2} alt="" />
                </div>
                <div className="corporativePathItem big">
                    <img src={corporativeSmart3} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart4} alt="" />
                </div>
                <div className="corporativePathItem">
                    <img src={corporativeSmart5} alt="" />
                </div>
            </Slider>
        }
    }

    const handleTiktokEvent = () => {
        if (typeof window !== "undefined") {
            if (window.ttq != null) {
                // window.fbq('track', 'Lead', {currency: "USD", value: 9.99});
                window.ttq.track('ClickButton');
            }
        }
    }

    const css = `
        html, body{
            background-color: #101010;
        }
    `;
    

    return (
        <main>
            <MetaPixel />
            <style>{css}</style>
            <div className="corporative">
                <HeaderCorporative image={corporativeLogo} actLink="home" />

                <div className="corporativeBanner" id="home">
                    <div className="corporativeContainer">
                        <div className="corporativeBannerBox">
                            <div className="corporativeBannerInfo">
                                <h1 className="corporativeBannerTitle">Become a more successful entrepreneur in  15 minutes a day</h1>
                                <p className="corporativeBannerDescr">Stand out from the crowd with the world’s biggest ideas packed into bite-sized learning</p>
                                <div className="corporativeBannerDownload">
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" onClick={() => handleTiktokEvent()}>
                                        <img src={corporativeApple} alt="" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.aeer.aeerplatform" onClick={() => handleTiktokEvent()}>
                                        <img src={corporativeGoogleplay} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="corporativeBannerImage">
                                <img src={corporativeMan} alt="" />
                                <img src={corporativePhone} alt="" />
                            </div>
                        </div>
                        
                    </div>
                </div>

                <picture className="corporativeFirstShape">
                    <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                    <img src={corporativeShape1Mob} />
                </picture>

                <div className="corporativeBooks" id="book">
                    <div className="corporativeContainer">
                        <h2 className="corporativeBooksTitle">Accelerate your growth with content from the world’s top Creators</h2>
                        <div className="corporativeBooksTabs">
                            <span className={`corporativeBooksTab ${(activeTab === 'bio') && 'active'}`} onClick={() => setActiveTab('bio')}>Biographies</span>
                            <span className={`corporativeBooksTab ${(activeTab === 'book') && 'active'}`} onClick={() => setActiveTab('book')}>Books</span>
                            <span className={`corporativeBooksTab ${(activeTab === 'course') && 'active'}`}  onClick={() => setActiveTab('course')}>Courses</span>
                        </div>
                        {renderCourses()}
                    </div>
                </div>

                <div className="corporativeMoving" >
                    <div className="corporativeMovingCont">
                        <div className="corporativeMovingLine mov1">
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>It summarises in five minutes, the key points of the book - a bit like speed reading but you don’t have to - you get all the info you need.</span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>I’m enjoying how beneficially it is being able to understand books from my phone.</span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>Truly fantastic app for smart people with not enough spare time to read thousands of books. </span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>I have ADHD and FeelGrow has made reading less confusing to me</span>
                            </div>
                        </div>
                        <div className="corporativeMovingLine mov2">
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>It summarises in five minutes, the key points of the book - a bit like speed reading but you don’t have to - you get all the info you need.</span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>I’m enjoying how beneficially it is being able to understand books from my phone.</span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>Truly fantastic app for smart people with not enough spare time to read thousands of books. </span>
                            </div>
                            <div className="corporativeMovingItem">
                                <img src={corporativeUser} alt="" />
                                <span>I have ADHD and FeelGrow has made reading less confusing to me</span>
                            </div>
                        </div>
                    </div>
                </div>

                <center>
                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" onClick={() => handleTiktokEvent()}>
                        <button className="corporativeBtn corporativeMovingBtn">
                            <span>Get Started</span>
                        </button>
                    </a>
                </center>

                <div className="corporativeShapeCont" id="third">
                    <picture className="corporativeShapeTop">
                        <source srcSet={corporativeShape2} media="(min-width: 768px)" />
                        <img src={corporativeShape2Mob} />
                    </picture>
                    <div className="corporativeScreens">
                        <div className="corporativeContainer">
                        <div className="corporativeScreensRow">
                                <div className="corporativeScreensImage left">
                                    {/* <img src={corporativeScreen1} alt="" /> */}
                                    <picture>
                                        <source srcSet={corporativeScreen1} media="(min-width: 768px)" />
                                        <img src={corporativeScreenMob1} />
                                    </picture>
                                </div>
                                <div className="corporativeScreensInfo">
                                    <span>Choose what works for you</span>
                                    <p>Whether you’re sitting down to concentrate or out on the go, our courses are available as easy stories you can tap through, text to read, or audio to listen to.</p>
                                </div>
                            </div>

                            <div className="corporativeScreensRow">
                                <div className="corporativeScreensInfo">
                                    <span>Curated for quality</span>
                                    <p>Our content team creates only the most relevant courses for entrepreneurs at any stage. We select only the books from top creators – with New York Times best sellers. The most useful knowledge is presented in a quick bite-sized format.</p>
                                </div>
                                <div className="corporativeScreensImage right">
                                    <picture>
                                        <source srcSet={corporativeScreen2} media="(min-width: 768px)" />
                                        <img src={corporativeScreenMob2} />
                                    </picture>
                                    {/* <img src={corporativeScreen2} alt="" /> */}
                                </div>
                            </div>

                            <div className="corporativeScreensRow">
                                <div className="corporativeScreensImage left">
                                    <picture>
                                        <source srcSet={corporativeScreen3} media="(min-width: 768px)" />
                                        <img src={corporativeScreenMob3} />
                                    </picture>
                                    {/* <img src={corporativeScreen3} alt="" /> */}
                                </div>
                                <div className="corporativeScreensInfo">
                                    <span>Built for learning</span>
                                    <p>FeelGrow uses scientifically proven methods to help you learn fast and retain your knowledge.</p>
                                </div>
                            </div>

                            <div className="corporativeScreensRow">
                                <div className="corporativeScreensInfo">
                                    <span>Take action</span>
                                    <p>We don’t just leave you to consume the facts, but provide you with actionable takeaways that you can use in your everyday life.</p>
                                </div>
                                <div className="corporativeScreensImage right">
                                    <picture>
                                        <source srcSet={corporativeScreen4} media="(min-width: 768px)" />
                                        <img src={corporativeScreenMob4} />
                                    </picture>
                                    {/* <img src={corporativeScreen4} alt="" /> */}
                                </div>
                            </div>

                            <div className="corporativeScreensRow">
                                <div className="corporativeScreensImage left">
                                    {/* <img src={corporativeScreen5} alt="" /> */}
                                    <picture>
                                        <source srcSet={corporativeScreen5} media="(min-width: 768px)" />
                                        <img src={corporativeScreenMob5} />
                                    </picture>
                                </div>
                                <div className="corporativeScreensInfo">
                                    <span>Using the latest in tech</span>
                                    <p>FeelGrow has algorithms that use smart AI to recommend only the most relevant content for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <picture className="corporativeShapeBott">
                        <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                        <img src={corporativeShape1Mob} />
                    </picture>
                </div>

                <div className="corporativePath" id="tools">
                    <div className="corporativeContainer big">
                        <h2 className="corporativeFeedTitle">Start your own path to knowledge</h2>
                        {renderScreens()}
                    </div>
                </div>

                <div className="corporativeShapeCont">
                    <picture className="corporativeShapeTop">
                        <source srcSet={corporativeShape2} media="(min-width: 768px)" />
                        <img src={corporativeShape2Mob} />
                    </picture>
                    <div className="corporativeLearn">
                        <h2 className="corporativeLearnTitle">LEARN SOMETHING NEW IN 15 MINUTES A DAY</h2>
                        <div className="corporativeLearnBox">
                            <div className="corporativeLearnImage">
                                <img src={corporativeLearn} alt="" />
                            </div>
                            <div className="corporativeLearnInfo">
                                <ul className="corporativeLearnList">
                                    <li className="active">Feed your brain while</li>
                                    <li>Driving</li>
                                    <li>Commuting</li>
                                    <li>Doing housework</li>
                                    <li>Walking</li>
                                    <li>Relaxing</li>
                                </ul>
                                <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" onClick={() => handleTiktokEvent()}>
                                    <button className="corporativeLearnBtn corporativeBtn">
                                        <span>Get Started</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <picture className="corporativeShapeBott">
                        <source srcSet={corporativeShape1} media="(min-width: 768px)" />
                        <img src={corporativeShape1Mob} />
                    </picture>
                </div>

                <div className="corporativeFeed">
                    <div className="corporativeContainer">
                        <h2 className="corporativeFeedTitle">What our users are saying</h2>
                        {renderFeed()}
                    </div>
                </div>
                <footer className="corporativeFooter">
                    <div className="corporativeContainer">
                        <div className="corporativeFooterBox">
                            <div className="corporativeFooterLeft">
                                <div className="corporativeFooterUser">
                                    <img src={AvatarImage} alt="" />
                                </div>
                                <div className="corporativeFooterInfo">
                                    <span>CEO</span>
                                    <b>Andrew Filatov</b>
                                    <p>My goal is to create a product that <br /> helps people improve themselves</p>
                                </div>
                            </div>
                            <div className="corporativeFooterCenter">
                                <div className="corporativeFooterSoc desk">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <div className="corporativeFooterPayment">
                                    <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" onClick={() => handleTiktokEvent()}>
                                        <img src={corporativeApple} alt="" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.aeer.aeerplatform" onClick={() => handleTiktokEvent()}>
                                        <img src={corporativeGoogleplay} alt="" />
                                    </a>
                                </div>

                                <div className="corporativeFooterNav">
                                    <Link to="/terms">Terms of User</Link>
                                    <Link to="/privacy">Privacy policy</Link>
                                    <Link to="/cookie">Cookie policy</Link>
                                    <Link to="/cancel">Unsubscribe</Link>
                                </div>
                            </div>
                            <div className="corporativeFooterRight">
                                <div className="corporativeFooterSoc mob">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <img src={BigLogoRight} alt="" className="corporativeFooterLogo" />
                                <p>© AEER PLATFORM INC <br />
                                8 THE GREEN STE A DOVER, DE 19901</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

            <ToastContainer/>
        </main>
    )
}

export default MainCorporativePage

export const Head: HeadFC = () => <title>AEER Platform</title>
